/**
 * Fixes for IE (v11 and Edge)
 *
 * @author HGB3 05/15/2017
 */

/**
 * String.startsWith polyfill
 *
 * @see https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith#Polyfill
 */
if ( ! String.prototype.startsWith ) {
	String.prototype.startsWith = function ( searchString, position ) {
		position = position || 0;
		return this.substr( position, searchString.length ) === searchString;
  };
}


/**
 * String.includes polyfill
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes#Polyfill
 */
if ( ! String.prototype.includes ) {
	String.prototype.includes = function( search, start ) {
		'use strict';
		if ( typeof start !== 'number' ) { start = 0; }

		if ( start + search.length > this.length ) {
			return false;
		} else {
			return this.indexOf( search, start ) !== -1;
		}
	};
}



/**
 * CustomEvents() polyfill
 *
 * @see https://github.com/d4tocchini/customevent-polyfill
 * @see https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent#Polyfill
 */
try {
	var ce = new window.CustomEvent( 'test' );
	ce.preventDefault();
	if ( ce.defaultPrevented !== true ) {
		// IE has problems with .preventDefault() on custom events
		// http://stackoverflow.com/questions/23349191
		throw new Error( 'Could not prevent default' );
	}
} catch( e ) {
	var CustomEvent = function(event, params) {
		var evt, origPrevent;
		params = params || {
			bubbles: false,
			cancelable: false,
			detail: undefined
		};

		evt = document.createEvent("CustomEvent");
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		origPrevent = evt.preventDefault;
		evt.preventDefault = function () {
			origPrevent.call(this);
			try {
				Object.defineProperty(this, 'defaultPrevented', {
					get: function () { return true; }
				});
			} catch(e) {
				this.defaultPrevented = true;
			}
		};
		return evt;
	};

	CustomEvent.prototype = window.Event.prototype;
	window.CustomEvent = CustomEvent; // expose definition to window
}
